import { Menu, MenuButton, MenuList, Text, Image, VStack, HStack } from '@chakra-ui/react'
import { getChainInfo, getRawChainInfo } from 'constants/chainInfo'
import {
  SupportedChainId,
  getAvailableSpotChainIds,
} from 'constants/chains'
import { useSoftSelectChain } from 'hooks/useSelectChain'
import { useCloseModal } from 'state/application/hooks'
import { ApplicationModal } from 'state/application/reducer'
import { useChainId } from 'state/globalNetwork/hooks'
import { useAmendPath } from 'state/user/hooks'
import { useWeb3ReactWrapped } from 'hooks/web3'
import { SecondaryMediumIconRightButton } from 'components/Button/Secondary'
import { default as errorRed } from 'assets/icons/error-red.svg'
import { validateColor } from 'theme/colors'
import { getTypography } from 'theme/typographies'
import { SelectorRadioRow } from './SelectorRadioRow'

function Row({
  targetChain,
  onSelectChain,
}: {
  targetChain: SupportedChainId
  onSelectChain: (targetChain: number) => Promise<boolean>
}) {
  const { chainId: chainIdUser, account } = useWeb3ReactWrapped()
  const stateChainId = useChainId()

  const amendPath = useAmendPath()

  const onClick = async () => {
    let chainSwitchSuccessful = true
    // if not connected, the hook just changes the state chainId
    if (!account || targetChain !== chainIdUser || stateChainId !== targetChain) {
      amendPath(undefined, targetChain)
      chainSwitchSuccessful = await onSelectChain(targetChain)
    }

    closeModal()
  }

  const active = stateChainId === targetChain

  const { label, logoUrl } = getChainInfo(targetChain as SupportedChainId)

  const closeModal = useCloseModal(ApplicationModal.NETWORK_SELECTOR)

  const rowContent = (
    <SelectorRadioRow
      active={targetChain === stateChainId}
      key={targetChain}
      onClick={async () => await onClick()}
    >
      <HStack justifyContent="flex-start" alignItems="center">
        <Image src={logoUrl} w="16px" h="16px" alt="chain logo" />
        <Text
          style={getTypography('Typography/Small/Normal/Small 1')}
          color={
            targetChain === stateChainId
              ? validateColor('Text/Headings & Titles/Title-text')
              : validateColor('Text/Lables/Label-text-hover')
          }
        >
          {label}
        </Text>
      </HStack>
    </SelectorRadioRow>
  )

  if (active) {
    return (
      <VStack width="100%" gap="0.125rem">
        {rowContent}
      </VStack>
    )
  }

  return rowContent
}

export function MinimalNetworkSelector() {
  const chainId = useChainId()

  const info = chainId ? getRawChainInfo(chainId) : undefined

  const selectChain = useSoftSelectChain()

  const chainIsSupported = info !== undefined

  const NetworkLogo: React.FC = () => {
    return (
      <>
        <Image src={info?.logoUrl} w="20px" h="20px" />
      </>
    )
  }

  return !chainId ? null : (
    <Menu closeOnBlur={true}>
      <SecondaryMediumIconRightButton as={MenuButton} p="0.25rem 0.5rem" h={'30px'}>
        {chainIsSupported ? (
          <>
            <HStack>
              <Text>
                {info?.label}
              </Text>
              <NetworkLogo />
            </HStack>
          </>
        ) : (
          <HStack alignItems="center">
            <Image src={errorRed} alt="error-red" width="1.25rem" height="1.25rem" />
            <Text
              display="none"
              overflow="hidden"
              textOverflow="ellipsis"
              white-space="nowrap"
              margin="0 0.5rem 0 0.4rem"
              fontSize="1rem"
              width="fit-content"
              fontWeight={500}
              color={validateColor('Text/Lables/Lable-error')}
            >
              Switch Network
            </Text>
          </HStack>
        )}
      </SecondaryMediumIconRightButton>
      <MenuList
        border="none"
        boxShadow="dark"
        width="300px"
        backgroundColor={validateColor('Surface/Surface-primary')}
        padding="1rem"
        display={'flex'}
        flexDirection={'column'}
        alignItems={'flex-start'}
        gap={'0.5rem'}
      >
        <Row
          onSelectChain={async (targetChainId: SupportedChainId) => await selectChain(targetChainId)}
          targetChain={chainId}
          key={chainId}
        />
        {getAvailableSpotChainIds()
          .filter((i) => i !== chainId)
          .map((_chainId: SupportedChainId) => (
            <Row
              onSelectChain={async (targetChainId: SupportedChainId) => await selectChain(targetChainId)}
              targetChain={_chainId}
              key={_chainId}
            />
          ))}
      </MenuList>
    </Menu>
  )
}
