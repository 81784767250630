import { createSlice } from '@reduxjs/toolkit'
import { WalletType } from 'components/Web3Provider/evm/connectors';

export interface ConnectionState {
  errorByConnectionType: Record<WalletType, string | undefined>
}

export const initialState: ConnectionState = {
  errorByConnectionType: {
    [WalletType.injected]: undefined,
    [WalletType.metaMaskSDK]: undefined,
    [WalletType.coinbaseWalletSDK]: undefined,
    [WalletType.safe]: undefined,
    [WalletType.brave]: undefined,
    [WalletType.subwallet]: undefined,
    [WalletType.walletConnect]: undefined,
  },
}

const connectionSlice = createSlice({
  name: 'connection',
  initialState,
  reducers: {
    updateConnectionError(
      state,
      { payload: { connectionType, error } }: { payload: { connectionType: WalletType; error: string | undefined } }
    ) {
      state.errorByConnectionType[connectionType] = error
    },
  },
})

export const { updateConnectionError } = connectionSlice.actions
export default connectionSlice.reducer
