import { useAppSelector } from "state/hooks"
import { useAccount } from "wagmi"

/**
 * Get web3 details, return impersonated account for account
 */
export const useWeb3ReactWrapped = () => {
  const { impersontedAccount, useImpersonatedAccount } = useAppSelector((state) => state.globalNetwork)

  const { address, connector, chainId, ...rest } = useAccount()

  if (useImpersonatedAccount) return { account: impersontedAccount, connector, chainId, ...rest }
  return { account: address, connector, chainId, ...rest }
}