import { useMemo } from 'react'
import { useNetworkState } from 'state/globalNetwork/hooks'
import { useChainIdAndAccountHandling } from './useChainIdAndAccountHandling'
import { useWeb3ReactWrapped } from './web3'

/**
 * Provides a web3 provider with or without user's signer
 * Recreate web3 instance only if the provider changes
 * Sets account to undefined if user is connected to network with no implementation 
 */
const useActiveWeb3React = () => {
  const { chainId: chainIdWeb3, account: accountWeb3, ...web3React } = useWeb3ReactWrapped()

  useChainIdAndAccountHandling(chainIdWeb3, accountWeb3)
  const { chainId, account } = useNetworkState()

  const isSupported = useMemo(() => chainId === chainIdWeb3, [chainId, chainIdWeb3])

  return { isSupported, chainId, account: isSupported ? account : undefined, ...web3React }
}

export default useActiveWeb3React
