import { Suspense, lazy, useEffect } from 'react'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'
import { ChakraBaseProvider } from '@chakra-ui/react'
import useChakraThemes from 'theme'
import '@sjmc11/tourguidejs/src/scss/tour.scss'
import { RedirectToSwap } from './Swap/redirects'
import { RedirectToMargin } from './Margin/redirects'
import { MarginTradeType } from 'types/1delta'
import { USDC_ON, WRAPPED_NATIVE_CURRENCY } from 'constants/tokens'
import { SupportedChainId } from 'constants/chains'
import { RedirectToYields } from './Yields/redirects'
import { useAccount } from 'state/globalNetwork/hooks'
import { initTracking } from 'utils/analytics'
import { isProductionEnv } from 'utils/env'
import { Navbar } from 'components/Navbar'
import { AppWrapper, BodyWrapper, HeaderWrapper } from 'components/PageLayout'
import { useUserTheme } from 'state/user/hooks'
import ErrorBoundary from 'components/ErrorBoundary'
import { LargeLoader } from 'components/Loader'
import { ConfigProvider } from 'antd'
import { useAntDesignTheme } from 'theme/useAntDesignTheme'
import { Footer } from 'components/Footer'
import { usePollingXChain } from 'components/Polling'
import { handleCaching } from 'cacheControl'

const NotFound = lazy(() => import('./NotFound'))
// const UnderMaintenance = lazy(() => import('./UnderMaintenance'))
const EasyMode = lazy(() => import('./EasyMode'))
const Markets = lazy(() => import('./Markets'))
const MarketsNew = lazy(() => import('./MarketsNew'))

// UI v2
const Margin = lazy(() => import('./Margin'))
const Swap = lazy(() => import('./Swap'))
const Yields = lazy(() => import('./Yields'))
const Vote = lazy(() => import('./Vote'))
const XChain = lazy(() => import('./XChain'))

export default function App() {
  // handle cached files
  useEffect(() => handleCaching(), [])

  const { pathname } = useLocation()

  const themes = useChakraThemes()
  const selectedTheme = useUserTheme()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  const chain = 'mantle'
  const lender = 'lendle'
  const operation = MarginTradeType.Open
  const inputCurrency = USDC_ON(SupportedChainId.MANTLE).address
  const outputCurrency = WRAPPED_NATIVE_CURRENCY[SupportedChainId.MANTLE].address
  const defaultRouteUrl =
    `/margin?chain=${chain}&lender=${lender}` +
    `&operation=${operation}&inputCurrency=${inputCurrency}&outputCurrency=${outputCurrency}`

  const account = useAccount()

  useEffect(() => {
    initTracking(account)
  }, [account])

  const antDesignTheme = useAntDesignTheme()

  usePollingXChain()

  return (
    <ConfigProvider theme={antDesignTheme}>
      <ChakraBaseProvider theme={themes[selectedTheme]}>
        <AppWrapper>
          <ErrorBoundary>
            <HeaderWrapper>
              <Navbar />
            </HeaderWrapper>
            <BodyWrapper>
              <Suspense fallback={<LargeLoader />}>
                <Switch>
                  {/* MARGIN */}
                  <Route exact path="/" component={() => <Redirect to={defaultRouteUrl} />} />
                  <Route exact path="/margin" component={Margin} />
                  <Route exact strict path="/margin/:chain/:lender" component={RedirectToMargin} />
                  <Route
                    exact
                    strict
                    path="/margin/:chain/:lender/:operation/:assetIn/:assetOut"
                    component={RedirectToMargin}
                  />
                  <Route exact strict path="/:chain/:lender" component={RedirectToMargin} />

                  {/* SPOT */}
                  <Route path="/swap" component={Swap} />
                  <Route exact strict path="/swap/:chain/" component={RedirectToSwap} />
                  <Route exact strict path="/swap/:chain/:outputCurrency" component={RedirectToSwap} />
                  <Route exact strict path="/swap/:chain/:inputCurrency/:outputCurrency" component={RedirectToSwap} />

                  {/* YIELDS */}
                  <Route exact strict path="/yields" component={Yields} />
                  <Route exact strict path="/yields/:chain/:lender" component={RedirectToYields} />

                  {/* MARKETS */}
                  <Route exact strict path="/markets" component={Markets} />

                  {/* EASY MODE */}
                  {!isProductionEnv() && <Route strict path="/easymode" component={EasyMode} />}

                  {/* VOTE */}
                  {!isProductionEnv() && <Route strict path="/vote" component={Vote} />}

                  {/* XCHAIN */}
                  {!isProductionEnv() && <Route strict path="/xchain" component={XChain} />}

                  {!isProductionEnv() && <Route exact strict path="/marketsnew" component={MarketsNew} />}

                  {/* BETA */}
                  <Route exact strict path="/beta" component={() => <Redirect to="/margin/mantle/lendle" />} />

                  <Route path="/*" component={NotFound} />
                </Switch>
                <Footer />
              </Suspense>
            </BodyWrapper>
          </ErrorBoundary>
        </AppWrapper>
      </ChakraBaseProvider>
    </ConfigProvider>
  )
}
