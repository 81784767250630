import '@reach/dialog/styles.css'
import 'inter-ui'
import 'analytics'
import 'animate.css'
import './index.css'

import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import Web3Provider from './components/Web3Provider'
import App from './pages/App'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import store from './state'
import ListsUpdater from './state/lists/updater'
import TransactionUpdater from './state/transactions/updater'
import UserUpdater from './state/user/updater'

if (!!window.ethereum) {
  window.ethereum.autoRefreshOnNetworkChange = false
}

function Updaters() {
  return (
    <>
      <ListsUpdater />
      <UserUpdater />
      <TransactionUpdater />
    </>
  )
}

const container = document.getElementById('root') as HTMLElement

createRoot(container).render(
  <StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <Web3Provider>
          <Updaters />
          <App />
        </Web3Provider>
      </BrowserRouter>
    </Provider>
  </StrictMode>
)

if (process.env.REACT_APP_SERVICE_WORKER !== 'false') {
  serviceWorkerRegistration.register()
}
