import { BALANCE_FETCH_URLS, FALLBACK_URLS, RPC_URLS } from './networks'
import ms from 'ms.macro'

export const AVERAGE_L1_BLOCK_TIME = ms`12s`

// get rpc url if exists
export const getRpcUrlByIndex = (chainId: number, id: number) => {
  const url = RPC_URLS[chainId]?.[id]
  if (!url) return getRpcUrlByIndex(chainId, id - 1)
  return url
}

// get fallback rpc url if exists
export const getFallbackRpcUrlByIndex = (chainId: number, id: number) => {
  const url = FALLBACK_URLS[chainId]?.[id]
  if (!url) return getFallbackRpcUrlByIndex(chainId, id - 1)
  return url
}

// get balance rpc url if exists
export const getBalanceRpcUrlByIndex = (chainId: number, id: number) => {
  const url = BALANCE_FETCH_URLS[chainId]?.[id--]
  if (!url) return getBalanceRpcUrlByIndex(chainId, id >= 0 ? id : 0)
  return url
}