import { useEffect } from 'react'
import { useAppSelector } from 'state/hooks'
import { Connector, useConnect } from 'wagmi'

export default function useEagerlyConnect() {

  const { connectors, connect } = useConnect()

  const selectedWallet = useAppSelector((state) => state.user.selectedWallet)

  const connectorAvailable: Connector | undefined = connectors.find(a => a.type === selectedWallet)


  useEffect(() => {
    async function f() {
      if (connectorAvailable) {
        const hasProvider = await connectorAvailable.getProvider()
        if (hasProvider)
          connect({ connector: connectorAvailable })
      }
    }
    f()
    // The dependency list is empty so this is only run once on mount
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
}
