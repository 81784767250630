import { useCallback } from "react"
import { Address, getAddress, isAddress, isHash, isHex, TransactionReceipt, TransactionRequest, TransactionRequestBase } from "viem"
import { useWeb3ReactWrapped } from "hooks/web3"
import { calculateGasMargin } from "utils/calculateGasMargin"
import { useGasData } from "state/globalNetwork/hooks"
import { getDefaultPublicClient, getWalletClient } from "components/Web3Provider/evm/connectors"

/** data to use for `.signTypedData({...})` */
export interface TypedEvmData {
  domain: Record<string, any>
  types: Record<string, any>
  message: Record<string, any>
  primaryType: string
}
/** message to use for `.signMessage({...})` */

export type TypedEvmMessageData = string
/** Generalized inputs for EVM off-chain signing */

export type EVMSignInputs = { typedData?: TypedEvmData; message?: TypedEvmMessageData }

export type EVMTransaction = TransactionRequest
export type ParametrizedEVMTransaction = (params: any) => TransactionRequestBase
export type EVMTransactionResponse = { hash: string }
export type EVMTransactionReceipt = TransactionReceipt

export enum EvmReceiptStatus {
  REVERTED = 'reverted',
  SUCCESS = 'success',
}

/** try to safely attempt to parse an address */
export function tryGetEVMAddress(addr: string | undefined) {
  try {
    if (!addr) return false
    return getAddress(addr)
  } catch (e) {
    return false
  }
}

/** create a general EVM txn */
export const createEVMTxn = (
  to: string,
  from: string,
  calldata: string,
  value: bigint | number | string = 0n
): EVMTransaction => {
  if (!isAddress(to)) throw new Error("Cannot create EVM txn without to address")
  if (!isAddress(from)) throw new Error("Cannot create EVM txn without from address")
  if (!isHex(calldata)) throw new Error("Cannot create EVM txn without calldata")
  return {
    to: to,
    from: from,
    data: calldata,
    value: BigInt(value)
  }
}


/**
 * Function to either sign a raw mesage (e.g. ERC20Permit) or typed data (e.g. Permit2)
 * Will sign the provided one.
 * Both cannot be used simultaneously
 */
async function signEVMDataOrMessage(
  chainId: number,
  _account: string | undefined,
  inputs: EVMSignInputs
) {

  // validation
  if (!_account) throw new Error("Cannot sign without signer and account")
  const client = getWalletClient(chainId, _account)
  let account = _account as Address
  if (inputs.typedData && inputs.message) throw new Error("Cannot sign both message and data")

  if (!inputs.message) {
    if (!inputs.typedData) throw new Error("No typed data to sign")

    return await client.signTypedData({
      account,
      ...inputs.typedData,
    })
  }

  // incorrect
  return await client.signMessage(
    {
      account,
      message: inputs.message
    }
  )
}


async function estimateEvmTransaction(
  account: string | undefined,
  chainId: number | undefined,
  transaction: EVMTransaction
) {
  if (!chainId) throw new Error("Cannot estimate gas without provider")
  if (!account || !isAddress(account)) throw new Error("Cannot estimate gas without account")
  return (await getDefaultPublicClient(chainId!)?.estimateGas({ ...transaction, account }))
}

async function waitForEVMTransaction(
  chainId: number | undefined,
  txHash: string
) {
  if (!chainId) throw new Error("Cannot wait for transaction without provider")
  if (!isHash(txHash)) throw new Error("Cannot wait for transaction without valid txHash")
  return await getDefaultPublicClient(chainId!)?.waitForTransactionReceipt({ hash: txHash })
}

export function useSendEVMTransaction() {
  const { account, chainId } = useWeb3ReactWrapped()

  const gasData = useGasData(chainId ?? 0)

  const sendTxn = useCallback(async (tx: EVMTransaction) => {
    let opts: any = {}
    const gasEstimate = await estimateEvmTransaction(account, chainId, tx)
    opts = gasEstimate
      ? {
        gasLimit: calculateGasMargin(gasEstimate, chainId),
        ...gasData,
      }
      : {}

    // imn the estimation, we already validate chainId and account
    return {
      hash: await getWalletClient(chainId!, account!)
        .sendTransaction({ account, ...tx, ...opts })
    }
  },
    [
      gasData,
      account,
      chainId,
    ]
  )

  const signTxn = useCallback(async (inputs: EVMSignInputs) => {
    return await signEVMDataOrMessage(
      chainId!,
      account,
      inputs
    )
  },
    [
      account,
      chainId,
    ]
  )

  const waitForTxn = useCallback(async (txHash: string) => {
    return waitForEVMTransaction(chainId, txHash)
  }, [chainId])

  return {
    sendTxn,
    signTxn,
    waitForTxn,
  }
}