import { createConfig, http, cookieStorage, createStorage } from 'wagmi';
import { mantle, taiko, avalanche, base, arbitrum, bsc, optimism, linea, polygon, blast } from 'wagmi/chains';
import { injected, safe, walletConnect, coinbaseWallet, metaMask } from 'wagmi/connectors';
import { Chain, createPublicClient, createWalletClient, custom } from 'viem';
import { Z_INDEX } from 'theme';

const chains = [mantle, taiko, avalanche, base, arbitrum, bsc, optimism, linea, polygon, blast] as Chain[]
const chainsNoMantle = [taiko, avalanche, base, arbitrum, bsc, optimism, linea, polygon, blast] as Chain[]

const darkmode = Boolean(window.matchMedia('(prefers-color-scheme: dark)'))

export const injectedConnector = injected()
export const metaMaskConnector = metaMask()
export const walletConnectConnector = walletConnect({
  projectId: process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID as string,
  showQrModal: true,
  qrModalOptions: {
    desktopWallets: undefined,
    enableExplorer: true,
    explorerExcludedWalletIds: undefined,
    explorerRecommendedWalletIds: undefined,
    mobileWallets: undefined,
    privacyPolicyUrl: undefined,
    termsOfServiceUrl: undefined,
    themeMode: darkmode ? 'dark' : 'light',
    themeVariables: {
      '--wcm-color-bg-3': 'unset',
      '--wcm-color-fg-2': 'unset',
      '--wcm-color-fg-3': 'transparent',
      '--wcm-container-border-radius': '8px',
      '--wcm-color-overlay': 'transparent',
      '--wcm-color-bg-2': 'rgba(255, 255, 255, 0.3)',
      '--wcm-overlay-backdrop-filter': 'blur(10px)',
      '--wcm-color-bg-1': 'rgba(255, 255, 255, 0.1)',
      '--wcm-accent-color': 'rgba(255, 255, 255, 0.5)',
      '--wcm-background-color': 'transparent',
      '--wcm-font-family': '"Inter custom", sans-serif',
      '--wcm-z-index': Z_INDEX.modal.toString(),
    } as any,
    walletImages: undefined,
  },
})
export const safeConnector = safe()
export const coinbaseConnector = coinbaseWallet()

export const evmConfig = createConfig({
  chains: [mantle, ...chainsNoMantle],
  ssr: true,
  storage: createStorage({
    storage: cookieStorage,
  }),
  transports: Object.assign({},
    ...chains.map(({ id }) => {
      return {
        [id]: http()
      }
    })
  ),
  batch: { multicall: true },
  connectors: [
    injectedConnector,
    coinbaseConnector,
    walletConnectConnector,
    safeConnector,
    metaMaskConnector,
  ],
});

export enum WalletType {
  metaMaskSDK = "metaMaskSDK",
  walletConnect = "walletConnect",
  coinbaseWalletSDK = "coinbaseWalletSDK",
  injected = "injected",
  safe = "safe",
  brave = "com.brave.wallet",
  subwallet = "app.subwallet",
}

export function getDefaultPublicClient(chainId: number) {
  const chain = chains.find(a => a.id === chainId)
  return createPublicClient({
    chain,
    transport: http(chain!.rpcUrls.default.http[0])
  })
}

export function getWalletClient(chainId: number, account: string) {
  const chain = chains.find(a => a.id === chainId)
  return createWalletClient({
    chain,
    account: account as any,
    transport: custom(window.ethereum! as any),
  })
}


