import useEagerlyConnect from 'hooks/useEagerlyConnect'
import { ReactNode } from 'react'
import { WagmiProvider } from 'wagmi'
import { evmConfig } from './evm/connectors'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

const queryClient = new QueryClient();


function AutoConnect() {
  useEagerlyConnect()

  return null
}

export default function Web3Provider({ children }: { children: ReactNode }) {
  return (
    <WagmiProvider config={evmConfig}>
      <QueryClientProvider client={queryClient}>
        <AutoConnect />
        {children}
      </QueryClientProvider>
    </WagmiProvider>
  )
}