import { useCallback, useState } from 'react'
import { useAppDispatch } from 'state/hooks'
import { updateSelectedWallet } from 'state/user/reducer'
import { shortenAddress } from 'utils'
import StatusIcon, { WalletIcon } from '../Identicon/StatusIcon'
import { useSelectChainNoWallet } from 'hooks/useSelectChain'
import { removeConnectedWallet } from 'state/wallets/reducer'
import { setAccount } from 'state/globalNetwork/actions'
import { useChainId } from 'state/globalNetwork/hooks'
import { trackWalletDisconnected } from 'utils/analytics'
import { HStack, VStack, Text, Divider, Box, Flex, Spinner } from '@chakra-ui/react'
import { validateColor } from 'theme/colors'
import { getTypography } from 'theme/typographies'
import { DisconnectButton } from 'components/Button/Action/DisconnectButton'
import { CopyButton } from 'components/Button/Action/CopyButton'
import { ModalCloseButton } from 'components/Button/Action/ModalCloseButton'
import { useWalletMenuDisclosure } from 'state/application/hooks'
import { useIsMobile } from 'hooks/useIsMobile'
import { WalletTabs } from './WalletTabs'
import { FadeInAnimation } from 'components/PageLayout'
import { SettingsButton } from './SettingsButton'
import { Connector } from 'wagmi'

interface AuthenticatedHeaderProps {
  account: string
  chainId: number
  connector: Connector
  openOptions: () => void
  onOpenSettings: () => void
}

const AuthenticatedHeader: React.FC<AuthenticatedHeaderProps> = ({
  account,
  chainId,
  connector,
  openOptions,
  onOpenSettings,
}) => {
  const appChainId = useChainId()
  const dispatch = useAppDispatch()

  const connectionType = connector.id

  const selectChain = useSelectChainNoWallet()

  const disconnect = useCallback(() => {
    const walletType = connector.name

    connector.disconnect()

    dispatch(updateSelectedWallet({ wallet: undefined }))
    dispatch(removeConnectedWallet({ account, walletType }))
    dispatch(setAccount({ account: undefined }))
    selectChain(appChainId)
    if (openOptions) openOptions()
    trackWalletDisconnected()
  }, [connector, dispatch, openOptions, account, selectChain, appChainId])

  const { onCloseWalletMenu } = useWalletMenuDisclosure()
  const sizeIsMobile = useIsMobile()

  const [totalBalance, setTotalBalance] = useState<number | undefined>(undefined)
  return (
    <VStack gap="0" height="100%" justifyContent="space-between" css={FadeInAnimation}>
      <VStack w="100%" gap="0" padding="1.5rem" pb="0">
        <HStack w="100%" justifyContent="space-between" alignItems={'flex-start'}>
          {totalBalance != undefined ? (
            <VStack alignItems={'flex-start'} w="100%">
              <Text
                color={validateColor('Text/Headings & Titles/Title-text')}
                style={getTypography('Typography/Sub-Headings/Bold/Sub-Heading 3')}
              >
                ${totalBalance.toFixed(2)}
              </Text>
            </VStack>
          ) : (
            <Spinner
              size={'lg'}
              color={validateColor('Text/Lables/Lable-success')}
              thickness="5px"
              mr="auto"
              mb="1.25rem"
            />
          )}
          {sizeIsMobile && <ModalCloseButton onClick={onCloseWalletMenu} />}
        </HStack>

        <Box w="100%" py="1rem">
          <Divider borderColor={validateColor('Borders/Border-secondary')} opacity={1} />
        </Box>

        <WalletTabs account={account} chainId={chainId} setTotalBalance={setTotalBalance} />
      </VStack>

      <HStack
        w="100%"
        justifyContent="space-between"
        borderRadius="0.5rem"
        boxShadow="0px -2px 2px 0px rgba(51, 74, 91, 0.42)"
        padding="1rem"
      >
        <HStack w="100%">
          <Flex>
            <StatusIcon size={32} />
            <Box
              position="relative"
              borderRadius="full"
              bg={validateColor('Surface/Surface-primary 2')}
              marginTop={'auto'}
              marginRight={'-0.75rem'}
              left={'-14px'}
              top="1px"
              p="0.25rem"
            >
              <WalletIcon type={connectionType} w="0.8rem" h="0.8rem" />
            </Box>
          </Flex>
          <Text
            color={validateColor('Text/Headings & Titles/Title-text') + ' !important'}
            style={getTypography('Typography/Body-Labels/Normal/Body')}
          >
            {account && shortenAddress(account, 3)}
          </Text>
          <CopyButton text={account || ''} />
        </HStack>
        <HStack>
          <SettingsButton onOpenSettings={onOpenSettings} />
          <DisconnectButton onClick={disconnect} aria-label="disconnect" />
        </HStack>
      </HStack>
    </VStack>
  )
}

export default AuthenticatedHeader
