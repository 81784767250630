import { SecondaryMediumButton } from '../Secondary'
import { ButtonProps, forwardRef, Image, ImageProps, Text } from '@chakra-ui/react'
import { CustomTooltip } from 'components/Tooltip'
import { validateColor } from 'theme/colors'

const ActionButton = forwardRef<ButtonProps & { imageProps: ImageProps | undefined; src: string }, 'button'>(
  (props, ref) => {
    const { imageProps, src, ...buttonProps } = props
    return (
      <SecondaryMediumButton
        p="0"
        minW={0}
        border="none"
        w={'2rem'}
        h={'2rem'}
        _active={{
          backgroundColor: validateColor('Surface/Surface-tertiary'),
        }}
        ref={ref}
        {...buttonProps}
      >
        <Image w={'1.2rem'} h={'1.2rem'} src={src} {...imageProps} />
      </SecondaryMediumButton>
    )
  }
)

export interface IconButtonWithTooltipProps extends ButtonProps {
  src: string
  tooltipLabel?: string
  imageProps?: ImageProps
}

export const IconButtonWithTooltip = forwardRef<IconButtonWithTooltipProps, 'button'>((props, ref) => {
  const { tooltipLabel, imageProps, ...buttonProps } = props
  return tooltipLabel ? (
    <CustomTooltip
      placement="top"
      innerBoxProps={{ border: 'none' }}
      p="0.5rem"
      tooltipContent={<Text color={validateColor('Text/Lables/Label-text-default')}>{tooltipLabel}</Text>}
    >
      <ActionButton imageProps={imageProps} ref={ref} {...buttonProps} />
    </CustomTooltip>
  ) : (
    <ActionButton imageProps={imageProps} {...buttonProps} />
  )
})
