import { Card, Img, Link, Text } from '@chakra-ui/react'
import { Connector } from 'wagmi'
import { validateColor } from 'theme/colors'

export function EvmWalletOption({
  link = undefined,
  connector = undefined,
  icon,
  isActive = false,
  id,
  tryActivation,
}: {
  link?: string | undefined
  connector?: undefined | Connector
  icon: string | undefined
  isActive?: boolean
  id: string
  tryActivation: (connector: Connector) => void
}) {
  const connectWc = async () => {
    if (connector) {
      const hasProv = await connector.getProvider()
      if (hasProv) {
        tryActivation(connector)
      }
    }
  }
  const content = (
    <Card
      width="100%"
      padding="1rem"
      background={validateColor('Surface/Surface-primary 2')}
      id={id}
      onClick={connectWc}
      display="flex"
      justifyContent="space-between"
      flexDirection="row"
      _hover={{
        cursor: 'pointer',
        background: validateColor('Surface/Surface-secondary'),
        transition: 'background-color 0.1s ease-in-out',
      }}
      alignItems="center"
    >
      <Text
        textAlign="left"
        color={
          isActive
            ? validateColor('Navigation/Navigation-text-active')
            : validateColor('Navigation/Navigation-text-inactive')
        }
      >
        {connector ? getWalletName(connector) : undefined}
      </Text>
      <Img src={getWalletIcon(connector?.id) ?? icon} alt={'Icon'} w="2rem" h="2rem" />
    </Card>
  )

  if (link) {
    return (
      <Link
        href={link}
        target="_blank"
        w="100%"
        _hover={{ textDecoration: 'none' }}
      >
        {content}
      </Link>
    )
  }

  return content
}

function getWalletName(connector: Connector) {
  switch (connector.id) {
    case WalletType.walletConnect: return 'WalletConnect'
    case WalletType.injected: return 'Browser Wallet'
    default: return connector.name
  }
}

import COINBASE_ICON_URL from 'assets/images/coinbaseWalletIcon.svg'
import INJECTED_ICON_URL from 'assets/images/arrow-right.svg'
import METAMASK_ICON_URL from 'assets/wallets/metamask.png'
import BRAVE_ICON_URL from 'assets/wallets/brave.png'
import SAFE_ICON_URL from 'assets/wallets/safe-logo.png'
import SUBWALLET_ICON_URL from 'assets/wallets/subwallet.svg'
import WALLET_CONNECT_ICON_URL from 'assets/images/walletConnectIcon.svg'
import { WalletType } from 'components/Web3Provider/evm/connectors'

export function getWalletIcon(t: string | undefined) {
  switch (t) {
    case WalletType.metaMaskSDK: return METAMASK_ICON_URL
    case WalletType.coinbaseWalletSDK: return COINBASE_ICON_URL
    case WalletType.walletConnect: return WALLET_CONNECT_ICON_URL
    case WalletType.brave: return BRAVE_ICON_URL
    case WalletType.safe: return SAFE_ICON_URL
    case WalletType.injected: return INJECTED_ICON_URL
    case WalletType.subwallet: return SUBWALLET_ICON_URL
    default: return undefined
  }
}