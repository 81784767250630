import { Box } from '@chakra-ui/react'
import { keyframes } from '@emotion/react'
import { validateColor } from 'theme/colors'

const animateC = keyframes`

  0%{
    transform:rotate(0deg);
  }
  100% {
    transform:rotate(360deg);
  }
`
const animate = keyframes`
  0%{
    transform:rotate(45deg);
  }
  100%{
    transform:rotate(405deg);
  }
`

const Ring: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <Box
    position="absolute"
    top="40%"
    left="50%"
    transform="translate(-50%, -50%)"
    width="150px"
    height="150px"
    background="transparent"
    border="3px solid"
    borderColor={validateColor('Surface/Surface-tertiary')}
    borderRadius="50%"
    textAlign="center"
    lineHeight="150px"
    fontSize="18px"
    color={validateColor('Button/Button Primary/Button-primary-default')}
    letterSpacing="4px"
    textTransform="uppercase"
    textShadow={`0 0 10px`}
    shadow={validateColor('Button/Button Primary/Button-primary-default')}
    _before={{
      content: '""',
      position: 'absolute',
      top: '-3px',
      left: '-3px',
      width: '100%',
      height: '100%',
      border: '3px solid transparent',
      borderTop: '3px solid',
      borderRight: '3px solid',
      borderRadius: '50%',
      animation: `${animateC} 2s linear infinite`,
    }}
  >
    {children}
  </Box>
)

const Load: React.FC = () => (
  <Box
    display="block"
    position="absolute"
    top="calc(50% - 2px)"
    left="50%"
    width="50%"
    height="4px"
    background="transparent"
    transformOrigin="left"
    animation={`${animate} 2s linear infinite`}
    _before={{
      content: '""',
      position: 'absolute',
      width: '16px',
      height: '16px',
      borderRadius: '50%',
      background: validateColor('Button/Button Primary/Button-primary-default'),
      top: '-6px',
      right: '-8px',
      boxShadow: `0 0 20px`,
      boxShadowColor: validateColor('Button/Button Primary/Button-primary-default'),
    }}
  />
)

export function LargeLoader() {
  return (
    <Ring>
      <Load />
      Loading
    </Ring>
  )
}
