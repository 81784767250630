import LibUpdater from 'lib/hooks/transactions/updater'
import { useCallback, useMemo } from 'react'
import { useAppDispatch, useAppSelector } from 'state/hooks'

import { L2_CHAIN_IDS } from '../../constants/chains'
import { checkedTransaction, finalizeTransaction } from './reducer'
import { useWeb3ReactWrapped } from 'hooks/web3'
import { Link, useToast } from '@chakra-ui/react'
import { ExplorerDataType, getExplorerLink } from 'utils/getExplorerLink'
import { EvmReceiptStatus, EVMTransactionReceipt } from 'calldata'

export default function Updater() {
  const { chainId } = useWeb3ReactWrapped()
  const toast = useToast()
  // speed up popup dismissal time if on L2
  const isL2 = Boolean(chainId && L2_CHAIN_IDS.includes(chainId))
  const transactions = useAppSelector((state) => state.transactions)

  const dispatch = useAppDispatch()
  const onCheck = useCallback(
    ({ chainId, hash, blockNumber }: { chainId: number; hash: string; blockNumber: number }) =>
      dispatch(checkedTransaction({ chainId, hash, blockNumber })),
    [dispatch]
  )
  const onReceipt = useCallback(
    ({ chainId, hash, receipt }: { chainId: number; hash: string; receipt: EVMTransactionReceipt }) => {
      dispatch(
        finalizeTransaction({
          chainId,
          hash,
          receipt: {
            blockHash: receipt.blockHash,
            blockNumber: receipt.blockNumber,
            contractAddress: receipt.contractAddress,
            from: receipt.from,
            status: receipt.status,
            to: receipt.to,
            transactionHash: receipt.transactionHash,
            transactionIndex: receipt.transactionIndex,
          },
        })
      )

      toast({
        title: (
          <Link href={getExplorerLink(chainId, hash, ExplorerDataType.TRANSACTION)} target="_blank">
            View on Explorer
          </Link>
        ),
        status: receipt.status === EvmReceiptStatus.SUCCESS ? 'success' : 'error',
        position: 'top-right',
        isClosable: true,
      })
    },
    [dispatch, isL2, transactions]
  )

  const pendingTransactions = useMemo(() => (chainId ? transactions[chainId] ?? {} : {}), [chainId, transactions])

  return <LibUpdater pendingTransactions={pendingTransactions} onCheck={onCheck} onReceipt={onReceipt} />
}
