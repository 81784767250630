import { Image, ImageProps } from '@chakra-ui/react'
import CoinbaseWalletIcon from 'assets/images/coinbaseWalletIcon.svg'
import WalletConnectIcon from 'assets/images/walletConnectIcon.svg'
import Identicon from '.'
import { getInjection } from 'connection/utils'
import { WalletType } from 'components/Web3Provider/evm/connectors'
import { getWalletIcon } from 'components/WalletMenu/EvmOption'

export default function StatusIcon({ size = 20 }: { size?: number }) {
  return <Identicon size={size} />
}

export const WalletIcon: React.FC<ImageProps & { type: string }> = ({ type, ...props }) => {
  const icon = getWalletIcon(type)
  if (icon) return <Image src={icon} alt="Injected Wallet Icon" {...props} />
  if (type === WalletType.injected) {
    const wallet = getInjection()
    return <Image src={wallet.icon} alt="Injected Wallet Icon" {...props} />
  } else if (type === WalletType.walletConnect) {
    return <Image src={WalletConnectIcon} alt="WalletConnect" {...props} />
  } else if (type === WalletType.coinbaseWalletSDK) {
    return <Image src={CoinbaseWalletIcon} alt="Coinbase Wallet" {...props} />
  }
  return null
}
